import { FC } from "react";
import { Box, IconButton, styled, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LifeBuoy } from "react-feather";

export const speedColors = [
  "#b52424",
  "#ff6058",
  "#ffce09",
  "#4caf50",
  "#1b5e20",
  "#808080"
];

const CustomIconButton = styled(IconButton)(() => ({
  position: "absolute",
  right: 10,
  top: 100,
  backgroundColor: "#fff",
  borderRadius: "2px",
  svg: {
    color: "#666666"
  }
}));

const Container = styled("div")(({ theme }) => ({
  right: 0,
  bottom: "24px",
  padding: theme.spacing(1),
  marginRight: "8px",
  position: "absolute",
  backgroundColor: theme.palette.background.default,
  // "&:hover": {
  //   backgroundColor: "#fff"
  // },
  ".MuiTypography-root": {
    fontSize: 11
  },
  ".colorBox": {
    width: 16,
    height: 16,
    borderRadius: "3px",
    marginRight: "5px"
  }
}));

type RouteFlowMapLegendParams = {
  onFitBoundsClick: () => void;
};

const RouteFlowMapLegend: FC<RouteFlowMapLegendParams> = ({
  onFitBoundsClick
}) => {
  const { t } = useTranslation();
  return (
    <>
      <CustomIconButton sx={{ display: "none" }} onClick={onFitBoundsClick}>
        <LifeBuoy />
      </CustomIconButton>
      <Container>
        <Typography>
          <strong>{t("FlowDashboardPage.mapLegend.title")}</strong>
        </Typography>
        {speedColors.map((color, idx) => (
          <Box key={color} sx={{ display: "flex", my: 1 }}>
            <div className="colorBox" style={{ backgroundColor: color }} />
            <Typography>
              {t(`FlowDashboardPage.mapLegend.level${idx + 1}`)}
            </Typography>
          </Box>
        ))}
      </Container>
    </>
  );
};

export default RouteFlowMapLegend;
