import {
  Box,
  Grid,
  styled,
  TextField,
  Typography,
  Autocomplete,
  Divider
} from "@mui/material";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import InputField from "components/InputField";
import { Controller, useForm } from "react-hook-form";
import useCameraAPI, { CameraFilter } from "api/CameraAPI";
import TimePickerField from "components/TimePickerField";
import DatePickerField from "components/DatePickerField";
import useLocationAPI from "api/LocationAPI";
import {
  endOfDay,
  setHours,
  setMinutes,
  startOfDay,
  subMonths
} from "date-fns";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { useCloneDashboardFilter } from "./CloneDashboardFilterContext";
import Drawer from "components/Drawer";
import FormLabel from "components/FormLabel";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormCloneDashboardFilter = {
  plate?: string;
  startDate: string | Date;
  endDate: string | Date;
  startTime: string | Date;
  endTime: string | Date;
  cameras: string[] | undefined;
  equipments: string[] | undefined;
};

const defaultDate = new Date();

export const defaultValues: FormCloneDashboardFilter = {
  plate: "",
  startDate: setMinutes(setHours(defaultDate.setDate(1), 0), 0),
  endDate: new Date(),
  startTime: startOfDay(defaultDate),
  endTime: endOfDay(defaultDate),
  cameras: undefined,
  equipments: undefined
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
};

const CloneDashboardFilter: FC<Props> = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const CameraAPI = useCameraAPI();
  const { sessionUser } = useAuth();
  const LocationAPI = useLocationAPI();
  const { errorHandler } = useErrorHandler();
  const { setFilterData } = useCloneDashboardFilter();
  const [cameras, setCameras] = useState<CameraFilter[]>([]);
  const [locationsName, setlocationsName] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCameras, setSelectedCameras] = useState<CameraFilter[]>([
    {
      ["location_name"]: t("form.all"),
      ["camera_name"]: t("form.all")
    }
  ]);
  const [selectedEquipments, setSelectedEquipments] = useState<string[]>([
    t("form.all")
  ]);
  const { control, formState, handleSubmit, reset, setValue } =
    useForm<FormCloneDashboardFilter>({
      mode: "onChange",
      defaultValues
    });

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      const [locationResponse, camerasResponse] = await Promise.all([
        LocationAPI.listAll(sessionUser.customer_id),
        CameraAPI.listAll({ customerId: sessionUser.customer_id })
      ]);
      setCameras([
        t("form.all"),
        ...camerasResponse.data.map(camera => ({
          ["location_name"]: camera["location_name"],
          ["camera_name"]: camera["camera_name"]
        }))
      ]);
      setlocationsName([
        t("form.all"),
        ...locationResponse.data.map(location => location.location_name)
      ]);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const clear = () => {
    reset(defaultValues);
    const d = setMinutes(setHours(new Date().setDate(1), 0), 0);
    setValue(
      "startDate",
      subMonths(setMinutes(setHours(d.setDate(1), 0), 0), 1)
    );
    setValue("startTime", startOfDay(defaultDate));
    setValue("endDate", new Date());
    setValue("endTime", endOfDay(defaultDate));
    setSelectedEquipments([t("form.all")]);
    setSelectedCameras([
      {
        ["location_name"]: t("form.all"),
        ["camera_name"]: t("form.all")
      }
    ]);
  };

  const onSubmit = async (data: FormCloneDashboardFilter) => {
    const newData = { ...data };

    newData.startDate = new Date(newData.startDate);
    newData.endDate = new Date(newData.endDate);
    newData.startTime = new Date(newData.startTime);
    newData.endTime = new Date(newData.endTime);

    if (
      selectedEquipments.includes(t("form.all")) ||
      selectedEquipments.length === 0
    ) {
      newData.equipments = undefined;
    } else {
      newData.equipments = selectedEquipments;
    }

    const IsAll = selectedCameras.filter(
      c => c["location_name"] === t("form.all")
    );

    if (IsAll.length > 0 || selectedCameras.length === 0) {
      newData.cameras = undefined;
    } else {
      newData.cameras = selectedCameras.map(camera => camera.camera_name);
    }

    setFilterData(newData);
    setOpen(false);
  };

  return (
    <Drawer
      open={open}
      setOpen={setOpen}
      title={t("MonitoredVehiclesDashboardPage.filter")}
    >
      <Divider sx={{ mb: 2 }} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={8}>
              <Controller
                name="startDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("MonitoredVehiclesDashboardPage.startDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sx={{ marginTop: "25px" }}>
              <Controller
                name="startTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="endDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("MonitoredVehiclesDashboardPage.endDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4} sx={{ marginTop: "25px" }}>
              <Controller
                name="endTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider>
                <Typography variant="h5" color="primary.light">
                  {t("DefaultPageLayout.plateSearch")}
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="plate"
                control={control}
                render={({ field, fieldState }) => (
                  <InputField
                    label={t("MonitoringReportPage.plate")}
                    uppercase
                    gutter={false}
                    field={{ ...field }}
                    fieldState={fieldState}
                    customProps={{
                      inputProps: {
                        maxLength: 7
                      }
                    }}
                  />
                )}
              />
              <Typography variant="caption" align="left" color="primary.light">
                {t("DefaultPageLayout.tooltipPlate")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider>
                <Typography variant="h5" color="primary.light">
                  {t("DefaultPageLayout.locationSearch")}
                </Typography>
              </Divider>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>
                {t("MonitoredVehiclesDashboardPage.equipments")}
              </FormLabel>
              <Autocomplete
                multiple
                data-testid="equipmentsAutocomplete"
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={locationsName}
                getOptionLabel={option => option}
                filterSelectedOptions
                onChange={(event, newValues) => {
                  event.preventDefault();
                  const noIsAll = !newValues.includes(t("form.all"));
                  if (
                    selectedEquipments.includes(t("form.all")) &&
                    selectedEquipments.length === 1
                  ) {
                    const idx = newValues.indexOf(t("form.all"));
                    newValues.splice(idx, 1);
                    setSelectedEquipments(newValues);
                  } else if (noIsAll) {
                    setSelectedEquipments(newValues);
                  } else {
                    setSelectedEquipments([t("form.all")]);
                    setSelectedCameras([
                      {
                        ["location_name"]: t("form.all"),
                        ["camera_name"]: t("form.all")
                      }
                    ]);
                  }
                }}
                noOptionsText={t("form.noOptions")}
                value={selectedEquipments}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t(
                      "MonitoredVehiclesDashboardPage.equipmentHint"
                    )}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("FlowDashboardPage.cameras")}</FormLabel>
              <Autocomplete
                multiple
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={[
                  {
                    ["location_name"]: t("form.all"),
                    ["camera_name"]: t("form.all")
                  },
                  ...cameras.filter(c =>
                    selectedEquipments.includes(c["location_name"])
                  )
                ]}
                getOptionLabel={option => option.camera_name}
                filterSelectedOptions
                onChange={(event, newValues) => {
                  event.preventDefault();
                  const noIsAll = newValues.findIndex(
                    c => c["location_name"] === t("form.all")
                  );
                  const allArray = selectedCameras.filter(
                    c => c["location_name"] === t("form.all")
                  );
                  if (allArray.length > 0 && selectedCameras.length === 1) {
                    newValues.splice(noIsAll, 1);
                    setSelectedCameras(newValues);
                  } else if (noIsAll === -1) {
                    setSelectedCameras(newValues);
                  } else {
                    setSelectedCameras([
                      {
                        ["location_name"]: t("form.all"),
                        ["camera_name"]: t("form.all")
                      }
                    ]);
                  }
                }}
                noOptionsText={t("form.noOptions")}
                value={selectedCameras}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t("FlowDashboardPage.camerasHint")}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Content>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: () => clear()
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              disabled: !formState.isValid || isLoading,
              type: "submit"
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default CloneDashboardFilter;
