import { Grid, Typography } from "@mui/material";
import ImageViewer, { ImageCoordinate } from "components/ImageViewer";
import { EMPTY_VALUE } from "utils/String";
import LinkPlate from "components/LinkPlate";
import { useTranslation } from "react-i18next";
import { FC, useState } from "react";
import TabPanel from "components/TabPanel";
import Pages from "enums/Pages";
import { RestrictionType } from "api/RestrictionAPI";
import FormDialog from "components/FormDialog";
import { dateToString } from "utils/DateFunctions";

export type IncidentDialog = {
  restriction: RestrictionType;
  id: string;
  image: string;
  plateCoordinate?: ImageCoordinate;
  equipment: string;
  camera: string;
  dateTime: string;
  plate: string;
  longitude?: string;
  latitude?: string;
  statistic?: {
    ocrReadTime: string;
    ocrPlateColor: string;
    motorcycle?: boolean;
    country: string;
  };
  renavam?: {
    vehicleMake: string;
    vehicleModel: string;
    vehicleColor: string;
  };
  city?: string;
  state?: string;
};

export const initialIncidentImageDialogProps: IncidentDialog = {
  restriction: "blocked_road",
  id: "",
  image: "",
  equipment: "",
  camera: "",
  dateTime: "",
  plate: "",
  city: "",
  state: ""
};

type Props = {
  open: boolean;
  incident: IncidentDialog | null;
  setOpen: (open: boolean) => void;
};

const IncidentReportViewDialog: FC<Props> = ({ open, setOpen, incident }) => {
  const { t } = useTranslation();
  const [isFullscreen, setIsFullscreen] = useState(false);

  const closeDialog = () => {
    if (isFullscreen) {
      setIsFullscreen(false);
    } else {
      setOpen(false);
    }
  };

  return (
    <FormDialog
      noCancel
      maxWidth="lg"
      open={open}
      setOpen={setOpen}
      title={t("IncidentDialog.incidentDetails")}
    >
      {incident && (
        <Grid container rowSpacing={1} columnSpacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={isFullscreen ? 12 : 6}>
            <ImageViewer
              isDialogOpen={open}
              isFullscreen={isFullscreen}
              setIsFullscreen={setIsFullscreen}
              img={incident.image}
              id={incident.id}
              cameraId={incident.camera}
              plateCoordinate={incident.plateCoordinate}
            />
          </Grid>
          <Grid hidden={isFullscreen} item xs={12} sm={12} md={12} lg={6}>
            <TabPanel
              tabs={[
                {
                  value: "info",
                  label: t("IncidentDialog.info"),
                  content: (
                    <Grid container rowSpacing={3} columnSpacing={2}>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("IncidentDialog.incidentType")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {t(`RestrictionsPage.type.${incident.restriction}`)}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("IncidentDialog.plate")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {incident.plate ? (
                            <LinkPlate
                              plate={incident.plate}
                              onClick={closeDialog}
                              path={Pages.INCIDENT_REPORT}
                            />
                          ) : (
                            EMPTY_VALUE
                          )}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("IncidentDialog.datetime")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {incident.dateTime
                            ? dateToString(incident.dateTime)
                            : EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("IncidentDialog.equipment")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {incident.equipment || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("IncidentDialog.camera")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {incident.camera || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("IncidentDialog.id")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {incident.id || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                },
                {
                  value: "renavam",
                  label: t("ImageDialog.renavam"),
                  hide: !incident.renavam,
                  content: (
                    <Grid container rowSpacing={3} columnSpacing={2}>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.make")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {incident.renavam?.vehicleMake || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.model")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {incident.renavam?.vehicleModel || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                      <Grid item sm={4} xs={12}>
                        <Typography variant="h5" align="left">
                          {t("ImageDialog.color")}
                        </Typography>
                        <Typography color="textSecondary" variant="caption">
                          {incident.renavam?.vehicleColor || EMPTY_VALUE}
                        </Typography>
                      </Grid>
                    </Grid>
                  )
                }
              ]}
            />
          </Grid>
        </Grid>
      )}
    </FormDialog>
  );
};

export default IncidentReportViewDialog;
