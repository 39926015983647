import { ReactNode } from "react";
import { AxiosResponse } from "axios";
import useAxios from "hooks/useAxios";
import { Grouping } from "./MonitoredVehiclesDashboardAPI";
import APIEndpoints from "enums/APIEndpoints";

export type HourRegister = {
  hour: number;
};

export type WeekRegister = {
  weekday: string;
};

export type MostMonitoredClass = {
  unknown: number;
  motorcycle: number;
  truck: number;
  bus: number;
  car: number;
};

export type RankingPlate = {
  plate: string;
  matches: number;
  icon?: ReactNode;
};

type PlateFrequency = {
  bin1: string[];
  bin5: string[];
  bin10: string[];
  bin50: string[];
  bin100: string[];
  bin500: string[];
  bin1000: string[];
  bin10000Plus: string[];
};

type RegisterFlowParams = {
  ["customer_id"]: string;
  ["initial_date"]: string;
  ["final_date"]: string;
  cameras?: string[] | undefined;
  equipments?: string[] | undefined;
  ranking?: number | undefined;
  grouping?: Grouping | undefined;
  histogram?: boolean;
};

type RegisterFlowResponse = {
  registers: {
    ["week"]: (MostMonitoredClass & WeekRegister)[];
    ["hour"]: (MostMonitoredClass & HourRegister)[];
    ["vehicle_class"]: MostMonitoredClass;
  };
};

export type RankingEquipments = {
  matches: string;
  name: string;
};

export type TopSpeedRank = {
  plate: string;
  speed: number;
};

type RegisterPlatesFlowResponse = {
  registers: {
    plateScore: RankingPlate[];
    histogram: PlateFrequency;
  };
};

type RegisterEquipmentsFlowResponse = {
  registers: RankingEquipments[];
};

type RouteTopSpeedParams = {
  ["customer_id"]: string;
  ["initial_date"]: string;
  ["final_date"]: string;
  ["route_name"]: string;
  ["initial_camera"]: string;
  ["final_camera"]: string;
  ranking: number;
};

type RouteTopSpeedResponse = {
  routeTopSpeed: TopSpeedRank[];
};

type UseFlowDashboardAPIType = {
  registerFlow: (param: RegisterFlowParams) => Promise<RegisterFlowResponse>;
  mostRegisteredPlates: (
    param: RegisterFlowParams
  ) => Promise<RegisterPlatesFlowResponse>;
  mostRegisteredEquipments: (
    param: RegisterFlowParams
  ) => Promise<RegisterEquipmentsFlowResponse>;
  routeTopSpeed: (param: RouteTopSpeedParams) => Promise<RouteTopSpeedResponse>;
};

const useFlowDashboardAPI = (): UseFlowDashboardAPIType => {
  const API = useAxios();

  const registerFlow = async (
    filter: RegisterFlowParams
  ): Promise<RegisterFlowResponse> => {
    const newFilter = { ...filter };

    if (!newFilter.equipments) delete newFilter.equipments;

    if (!newFilter.grouping) delete newFilter.grouping;

    if (!newFilter.ranking) delete newFilter.ranking;

    if (!newFilter.histogram) delete newFilter.histogram;

    const response: AxiosResponse<RegisterFlowResponse> =
      await API.instance.post(APIEndpoints.REGISTER_FLOW, newFilter);

    return response.data;
  };

  const mostRegisteredPlates = async (
    filter: RegisterFlowParams
  ): Promise<RegisterPlatesFlowResponse> => {
    const newFilter = { ...filter };

    if (!newFilter.cameras) delete newFilter.cameras;

    if (!newFilter.equipments) delete newFilter.equipments;

    if (!newFilter.grouping) delete newFilter.grouping;

    if (!newFilter.ranking) delete newFilter.ranking;

    const response: AxiosResponse<RegisterPlatesFlowResponse> =
      await API.instance.post(APIEndpoints.MOST_REGISTER_PLATES, newFilter);

    return response.data;
  };

  const mostRegisteredEquipments = async (
    filter: RegisterFlowParams
  ): Promise<RegisterEquipmentsFlowResponse> => {
    const newFilter = { ...filter };

    delete newFilter.cameras;
    delete newFilter.equipments;

    const response: AxiosResponse<RegisterEquipmentsFlowResponse> =
      await API.instance.post(APIEndpoints.MOST_REGISTER_EQUIPMENTS, newFilter);

    return response.data;
  };

  const routeTopSpeed = async (
    filter: RouteTopSpeedParams
  ): Promise<RouteTopSpeedResponse> => {
    const response: AxiosResponse<RouteTopSpeedResponse> =
      await API.instance.post(APIEndpoints.ROUTE_TOP_SPEED, filter);

    return response.data;
  };

  return {
    registerFlow,
    routeTopSpeed,
    mostRegisteredPlates,
    mostRegisteredEquipments
  };
};

export default useFlowDashboardAPI;
