import { Box, Grid, styled, Typography, Skeleton } from "@mui/material";
import Pages from "enums/Pages";
import Map, { MapGeolocation } from "components/Map";
import useMonitoringAPI from "api/MonitoringAPI";
import fileDownload from "js-file-download";
import { useAuth } from "contexts/AuthContext";
import DataTable from "components/DataTable";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import CaptureImgButton from "components/CaptureImgButton";
import { FC, useCallback, useEffect, useState } from "react";
import { Sliders, Download } from "react-feather";
import InnerPageLayout from "layouts/InnerPageLayout";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { usePageLocation } from "contexts/PageLocationContext";
import { useLocale } from "contexts/LocaleContext";
import useVehicleOverviewAPI, {
  CaptureReport,
  VehicleInfos
} from "api/VehicleOverviewAPI";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { dateTimeToString, dateToString } from "utils/DateFunctions";
import VehicleOverviewFilter, {
  FormVehicleOverviewFilter,
  defaultValues
} from "./VehicleOverviewFilter";
import { parsePlateCoordinate } from "components/ImageViewer";
import CaptureImageViewerDialog, {
  CaptureImageDialog,
  initialCaptureImageDialogProps
} from "components/CaptureImageViewerDialog/CaptureImageViewerDialog";
import { EMPTY_VALUE } from "utils/String";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import MenuButton from "components/MenuButton";

const Container = styled(Box)(({ theme }) => ({
  padding: "24px",
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.grey["200"]}`,
  backgroundColor: theme.palette.background.default
}));

const defaultVehicleInfos: VehicleInfos = {
  make: "",
  model: "",
  color: "",
  class: "",
  year: ""
};

const VehicleOverviewPage: FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { language } = useLocale();
  const { setPageTitle, setLocation } = usePageLocation();
  const { sessionUser } = useAuth();
  const params = useParams<{ plate: string }>();
  const plate: string = params.plate ? decodeURIComponent(params.plate) : "";
  const VehicleOverviewAPI = useVehicleOverviewAPI();
  const [isDownloadingCsv, setDownloadingCsv] = useState<boolean>(false);
  const [isDownloadingPdf, setDownloadingPdf] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isMonitoringLoading, setMonitoringLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [vehicleDatas, setVehicleDatas] = useState<CaptureReport[]>([]);
  const MonitoringAPI = useMonitoringAPI();
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [geolocations, setGeolocations] = useState<MapGeolocation[]>([
    {
      lat: -25.4320403321768,
      lng: -49.2454949242968,
      style: "circle"
    }
  ]);
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const [pageSize, setPageSize] = useState<number>(10);
  const { errorHandler } = useErrorHandler();
  const [isMonitoring, setIsMonitoring] = useState<boolean>(false);
  const [filter, setFilter] = useState<FormVehicleOverviewFilter>({
    ...defaultValues
  });
  const [isCaptureDialogOpen, setCaptureDialogOpen] = useState<boolean>(false);
  const [vehicleInfos, setVehicleInfos] =
    useState<VehicleInfos>(defaultVehicleInfos);
  const [capture, setCapture] = useState<CaptureImageDialog>(
    initialCaptureImageDialogProps
  );

  useEffect(() => {
    setPageTitle(t("windowTitle.vehicleOverview"));
    setLocation([
      {
        label: t("menu.system")
      },
      {
        label: t("VehicleOverviewPage.title")
      },
      {
        label: plate,
        page: `${Pages.VEHICLE_OVERVIEW}/${plate}`
      }
    ]);
  }, [t, Pages, plate]);

  useEffect(() => {
    (async () => {
      setMonitoringLoading(true);
      if (sessionUser) {
        try {
          const monitoringResponse = await MonitoringAPI.listFiltered(
            sessionUser.customer_id,
            {
              page: "1",
              soundAlert: "",
              plate: plate || "",
              type: "",
              endDate: "",
              startDate: "",
              equipment: ""
            },
            10
          );

          if (
            monitoringResponse.data.monitorings &&
            monitoringResponse.data.monitorings.length > 0
          ) {
            setIsMonitoring(true);
          } else {
            setIsMonitoring(false);
          }
        } catch (error) {
          errorHandler({ error });
        } finally {
          setMonitoringLoading(false);
        }
      }
    })();
  }, [plate]);

  const requestData = useCallback(
    async (
      filterParam: FormVehicleOverviewFilter,
      pageValue: number,
      pageSizeValue: number
    ) => {
      if (!sessionUser?.["customer_id"]) return;
      setLoading(true);
      try {
        let currentPlate = plate;
        if (
          (!plate && filterParam.plate) ||
          (plate && filterParam.plate && plate !== filterParam.plate)
        ) {
          history.replace(
            `${Pages.VEHICLE_OVERVIEW}/${encodeURIComponent(filterParam.plate)}`
          );
          setLocation([
            {
              label: t("menu.system")
            },
            {
              label: t("VehicleOverviewPage.title")
            },
            {
              label: plate,
              page: `${Pages.VEHICLE_OVERVIEW}/${filterParam.plate}`
            }
          ]);
          currentPlate = filterParam.plate;
        }

        const response = await VehicleOverviewAPI.list({
          ["customer_id"]: sessionUser["customer_id"],
          page: pageValue,
          ["page_size"]: pageSizeValue,
          ["initial_date"]: dateTimeToString(
            filterParam.startDate as Date,
            filterParam.startTime as Date
          ),
          ["final_date"]: dateTimeToString(
            filterParam.endDate as Date,
            filterParam.endTime as Date
          ),
          plate: currentPlate,
          cameras: filterParam.cameras,
          locations: filterParam.locations
        });

        if (response.registers.items.length > 0) {
          const configGeographic: MapGeolocation[] = [];
          let vehicleInformations: VehicleInfos = {
            make: "",
            model: "",
            color: "",
            class: "",
            year: ""
          };
          response.registers.items.forEach(vehicle => {
            configGeographic.push({
              lat: Number(vehicle.latitude),
              lng: Number(vehicle.longitude)
            });
            vehicleInformations = {
              make: vehicle.vehicle_make
                ? vehicle.vehicle_make
                : vehicleInformations.make,
              model: vehicle.vehicle_model
                ? vehicle.vehicle_model
                : vehicleInformations.model,
              color: vehicle.vehicle_color
                ? vehicle.vehicle_color
                : vehicleInformations.color,
              class: vehicle.vehicle_class
                ? vehicle.vehicle_class
                : vehicleInformations.class,
              year: vehicle.vehicle_year
                ? vehicle.vehicle_year
                : vehicleInformations.year
            };
          });
          setVehicleInfos(vehicleInformations);
          setGeolocations(configGeographic || []);
        }

        setVehicleDatas(response.registers.items || []);
        setPaginator({
          totalPages: response.registers.total_pages || 0,
          totalItems: response.registers.total_items || 0
        });
        setPage(response.registers.page || 1);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setLoading(false);
      }
    },
    [sessionUser, plate]
  );

  useEffect(() => {
    requestData(filter, page, pageSize);
  }, [requestData, pageSize, filter]);

  const downloadCsv = async () => {
    if (!filter || isDownloadingCsv || !sessionUser?.["customer_id"]) return;
    try {
      setDownloadingCsv(true);
      const response = await VehicleOverviewAPI.downloadCsv({
        ["customer_id"]: sessionUser.customer_id,
        ["initial_date"]: dateTimeToString(
          filter.startDate as Date,
          filter.startTime as Date
        ),
        ["final_date"]: dateTimeToString(
          filter.endDate as Date,
          filter.endTime as Date
        ),
        plate: !plate && filter.plate ? filter.plate : plate,
        cameras: filter.cameras,
        locations: filter.locations,
        language: language.split("-")[0]
      });
      fileDownload(response, `vehicle_${plate}_overview.csv`, "text/csv");
    } catch (error) {
      errorHandler({ error });
    } finally {
      setDownloadingCsv(false);
    }
  };

  const forceDownloadPdf = (pdf: string) => {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = `vehicle_${plate}_overview.pdf`;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const downloadPdf = async () => {
    if (!filter || isDownloadingPdf || !sessionUser?.["customer_id"]) return;
    try {
      setDownloadingPdf(true);
      const response = await VehicleOverviewAPI.downloadPdf({
        ["customer_id"]: sessionUser.customer_id,
        ["initial_date"]: dateTimeToString(
          filter.startDate as Date,
          filter.startTime as Date
        ),
        ["final_date"]: dateTimeToString(
          filter.endDate as Date,
          filter.endTime as Date
        ),
        plate: !plate && filter.plate ? filter.plate : plate,
        cameras: filter.cameras,
        locations: filter.locations,
        language: language.split("-")[0]
      });
      forceDownloadPdf(response);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setDownloadingPdf(false);
    }
  };

  const openCaptureDialog = (item: CaptureReport) => {
    setCapture({
      id: item.id,
      image: item.image_link,
      equipment: item.location_name,
      camera: item.camera_name,
      dateTime: item.date_capture,
      plate: item.plate,
      plateCoordinate: item.plate_coordinate
        ? parsePlateCoordinate(item.plate_coordinate)
        : undefined,
      longitude: item.longitude || "0",
      latitude: item.latitude || "0",
      renavam: {
        vehicleMake: item.vehicle_make,
        vehicleModel: item.vehicle_model,
        vehicleColor: item.vehicle_color,
        vehicleCategory: item.vehicle_class,
        vehicleYear: item.vehicle_year
      }
    });
    setCaptureDialogOpen(true);
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={plate}
          isLoading={isLoading || isMonitoringLoading}
          actions={
            <>
              <MenuButton
                id="download-button"
                disabled={paginator.totalItems < 1}
                icon={<Download />}
                label={t("action.export")}
                actions={[
                  {
                    key: "downloadCsv",
                    isLoading: isDownloadingCsv,
                    label: t("CaptureReportPage.exportCsv"),
                    onClick: downloadCsv
                  },
                  {
                    key: "downloadPdf",
                    isLoading: isDownloadingPdf,
                    label: t("CaptureReportPage.exportPdf"),
                    onClick: downloadPdf
                  }
                ]}
              />
              <PageSectionHeaderAction
                variant="outlined"
                color="primary"
                startIcon={<Sliders />}
                onClick={() => setFilterOpen(true)}
              >
                {t("action.filter")}
              </PageSectionHeaderAction>
            </>
          }
          primaryActions={
            <MenuButton
              primary
              id="monitoring-menu"
              disabled={isMonitoringLoading}
              label={
                isMonitoring
                  ? t("VehicleOverviewPage.monitored")
                  : t("VehicleOverviewPage.notMonitored")
              }
              actions={[
                {
                  key: "monitoringInfos",
                  label: t("VehicleOverviewPage.monitoringInfos"),
                  onClick: () =>
                    history.push(`${Pages.MONITORING_VEHICLES}/${plate}`)
                },
                {
                  key: "monitoringAdd",
                  label: t("VehicleOverviewPage.monitoringAdd"),
                  onClick() {
                    if (
                      !vehicleInfos.make &&
                      !vehicleInfos.model &&
                      !vehicleInfos.color
                    ) {
                      history.push(
                        `${Pages.MONITORING_VEHICLES}/${plate}/${vehicleInfos.class}`
                      );
                    } else {
                      history.push(
                        `${Pages.MONITORING_VEHICLES}/${plate}/${vehicleInfos.class}/${vehicleInfos.make}/${vehicleInfos.model}/${vehicleInfos.color}`
                      );
                    }
                  }
                }
              ]}
            />
          }
        >
          <Grid container rowSpacing={1} columnSpacing={1}>
            <Grid item xs={12} sm={4}>
              <Container
                sx={{
                  mb: 1,
                  display: "flex",
                  flexDirection: "column"
                }}
              >
                <Typography sx={{ mb: 2 }} variant="h4">
                  {t("VehicleOverviewPage.title")}
                </Typography>
                {isLoading ? (
                  <Skeleton variant="rectangular" height={124} />
                ) : (
                  <Grid container rowSpacing={1} columnSpacing={1}>
                    <Grid item xs={6}>
                      <Typography variant="caption">
                        {`${t("VehicleOverviewPage.class")}: ${
                          vehicleInfos.class === ""
                            ? t("VehicleOverviewPage.noInfo")
                            : t(`VehicleOverviewPage.${vehicleInfos.class}`)
                        }.`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">
                        {`${t("VehicleOverviewPage.make")}: ${
                          vehicleInfos.make || t("VehicleOverviewPage.noInfo")
                        }.`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">
                        {`${t("VehicleOverviewPage.model")}: ${
                          vehicleInfos.model || t("VehicleOverviewPage.noInfo")
                        }.`}
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="caption">
                        {`${t("VehicleOverviewPage.color")}: ${
                          vehicleInfos.color || t("VehicleOverviewPage.noInfo")
                        }.`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="caption">
                        {`${t("VehicleOverviewPage.year")}: ${
                          vehicleInfos.year || t("VehicleOverviewPage.noInfo")
                        }.`}
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Container>
              <Container
                sx={{ overflow: "hidden", display: "flex", minHeight: "710px" }}
              >
                <div
                  style={{
                    flexGrow: 1,
                    width: "100%"
                  }}
                >
                  {isLoading ? (
                    <Skeleton variant="rectangular" height={710} />
                  ) : (
                    <Map minHeight={710} geolocations={geolocations} />
                  )}
                </div>
              </Container>
            </Grid>
            <Grid item xs={12} sm={8}>
              <Box>
                <DataTable
                  watermarked
                  headers={[
                    {
                      key: "capture",
                      label: t("CaptureReportPage.capture"),
                      noSort: true
                    },
                    {
                      key: "equipment",
                      label: t("VehicleOverviewPage.equipment"),
                      noSort: true
                    },
                    {
                      key: "camera",
                      label: t("VehicleOverviewPage.camera"),
                      noSort: true
                    },
                    {
                      key: "date_capture",
                      label: t("VehicleOverviewPage.datetime")
                    }
                  ]}
                  defaultSort={["date_capture", "asc"]}
                  onHeaderSort={setVehicleDatas}
                  data={vehicleDatas}
                  renderRow={row => [
                    <>
                      <CaptureImgButton
                        image_link={row.image_link}
                        onClick={() => openCaptureDialog(row)}
                      />
                    </>,
                    <>{row.location_name || EMPTY_VALUE}</>,
                    <>{row.camera_name || EMPTY_VALUE}</>,
                    <>{dateToString(row.date_capture)}</>
                  ]}
                  hideColumnsSm={[2, 3]}
                  hideColumnsXs={[0, 2, 3]}
                  page={page}
                  onPageChange={pageValue =>
                    requestData(filter, pageValue, pageSize)
                  }
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                  totalPages={paginator.totalPages}
                  totalItems={paginator.totalItems}
                  isLoading={isLoading}
                />
              </Box>
            </Grid>
          </Grid>
        </PageSection>
        <VehicleOverviewFilter
          plate={plate ?? ""}
          open={isFilterOpen}
          setOpen={setFilterOpen}
          setFilterData={setFilter}
        />
        <CaptureImageViewerDialog
          open={isCaptureDialogOpen}
          setOpen={setCaptureDialogOpen}
          capture={capture}
          setCapture={setCapture}
        />
      </InnerPageLayout>
    </DefaultPageLayout>
  );
};

export default VehicleOverviewPage;
