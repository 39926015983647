import {
  Box,
  Dialog,
  Divider,
  Tooltip,
  useTheme,
  Typography,
  DialogActions,
  DialogContent
} from "@mui/material";
import { Edit, Plus, Users, MinusCircle, CheckCircle } from "react-feather";
import Pages from "enums/Pages";
import Button from "components/Button";
import debounce from "lodash/debounce";
import { useIsMount } from "hooks/useIsMount";
import { useAuth } from "contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { useModal } from "contexts/ModalContext";
import InnerPageLayout from "layouts/InnerPageLayout";
import AliasFormDialog from "components/AliasFormDialog";
import DataTable from "components/DataTable";
import EquipmentsFormDialog from "./EquipmentsFormDialog";
import DefaultPageLayout from "layouts/DefaultPageLayout";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { usePageLocation } from "contexts/PageLocationContext";
import { FC, useCallback, useEffect, useMemo, useState } from "react";
import useLocationAPI, { Location, LocationPaginated } from "api/LocationAPI";
import DataTableActions from "components/DataTableActions";
import PageSection from "components/PageSection/PageSection";
import PageSectionHeaderAction from "components/PageSection/PageSectionHeaderAction";
import {
  PaginatorModel,
  defaultPaginatorModelValues
} from "components/Paginator";
import { cepFormatter } from "utils/cepFormatter";

export const defaultEquipmentValues: Location = {
  ["location_name"]: "",
  ["customer_id"]: "",
  ["location_data"]: {
    code: "",
    type: "",
    ["zip_code"]: "",
    number: "",
    ["city"]: "",
    street: "",
    ["place_type"]: "",
    district: "",
    comment: "",
    complement: "",
    ["state"]: "",
    longitude: "",
    latitude: ""
  },
  cameras: [],
  ["shared_data"]: {
    ["owner_name"]: "",
    owner: "",
    alias: "",
    shared: []
  }
};

const EquipmentsPage: FC = () => {
  const LocationAPI = useLocationAPI();
  const isMount = useIsMount();
  const { showModal } = useModal();
  const [isFormDialogOpen, setIsFormDialogOpen] = useState(false);
  const [itemSelected, setItemSelected] = useState<Location>(
    defaultEquipmentValues
  );
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [aliasFormOpen, setAliasFormOpen] = useState<boolean>(false);
  const [pageSize, setPageSize] = useState<number>(10);
  const [itemCamSelected, setItemCamSelected] = useState<string>("");
  const [paginator, setPaginator] = useState<PaginatorModel>(
    defaultPaginatorModelValues
  );
  const theme = useTheme();

  const openFormDialog = (item: Location) => {
    setItemSelected(item);
    if (
      item.shared_data &&
      item.shared_data.owner !== "" &&
      item.shared_data.owner !== null &&
      item.shared_data.owner !== undefined
    ) {
      setAliasFormOpen(true);
    } else {
      setIsFormDialogOpen(true);
    }
  };
  const { t } = useTranslation();
  const { setPageTitle, setLocation } = usePageLocation();

  useEffect(() => {
    setPageTitle(t("windowTitle.equipments"));
    setLocation([
      {
        label: t("menu.system")
      },
      {
        label: t("menu.equipmentsAndCameras")
      },
      {
        label: t("EquipmentsPage.title"),
        page: Pages.EQUIPMENTS
      }
    ]);
  }, [t, Pages]);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [locationsName, setLocationsName] = useState<Location[]>([]);
  const { sessionUser } = useAuth();

  const requestData = useCallback(
    async (searchValue, pageValue, pageSizeValue: number) => {
      if (!sessionUser?.["customer_id"]) return;
      setIsLoading(true);
      try {
        let locationsResponse;
        if (searchValue) {
          locationsResponse = await LocationAPI.search(
            sessionUser["customer_id"],
            searchValue,
            pageValue,
            pageSizeValue
          );
        } else {
          locationsResponse = await LocationAPI.listPaginated(
            sessionUser["customer_id"],
            pageValue,
            pageSizeValue
          );
        }
        const locationsResponseValue =
          locationsResponse.data as LocationPaginated;
        setLocationsName(locationsResponseValue.locations || []);
        setPaginator({
          totalItems: locationsResponseValue.total_count || 0,
          totalPages: locationsResponseValue.total_pages || 0
        });
        setPage(pageValue);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsLoading(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    requestData(search, page, pageSize);
  }, [requestData, pageSize]);

  const fetch = useMemo(
    () =>
      debounce((searchValue: string, pageSizeValue: number) => {
        requestData(searchValue, page, pageSizeValue);
      }, 700),
    []
  );

  useEffect(() => {
    if (!isMount) {
      fetch(search, pageSize);
    }
  }, [search, fetch]);

  const handleRemove = (name: string) => {
    showModal({
      modalType: "confirm",
      onConfirm: () => remove(name),
      confirmText: t("action.confirm"),
      title: t("prompt.removeConfirmTitle", { name }),
      description: t("prompt.removeConfirmDescription")
    });
  };

  const { errorHandler } = useErrorHandler();

  const remove = async (name: string) => {
    try {
      if (!sessionUser?.["customer_id"]) return;
      await LocationAPI.deleteByName(sessionUser["customer_id"], name);
      requestData(search, page, pageSize);
    } catch (error) {
      errorHandler({ error });
    }
  };

  const [isConfirmCreateCameraOpen, setIsConfirmCreateCameraOpen] =
    useState(false);

  const onRequestCompleted = (isCreating: boolean, locationName: string) => {
    if (isCreating) {
      setIsConfirmCreateCameraOpen(true);
      setItemCamSelected(locationName);
    }
    requestData(search, page, pageSize);
  };

  return (
    <DefaultPageLayout>
      <InnerPageLayout>
        <PageSection
          title={t("EquipmentsPage.title")}
          isLoading={isLoading}
          search={{
            onChange: e => setSearch(e.target.value),
            placeholder: t("EquipmentsPage.searchEquipment")
          }}
          primaryActions={
            <PageSectionHeaderAction
              variant="contained"
              color="secondary"
              startIcon={<Plus />}
              onClick={() => openFormDialog(defaultEquipmentValues)}
            >
              {t("EquipmentsPage.newEquipments")}
            </PageSectionHeaderAction>
          }
        >
          <DataTable
            watermarked
            headers={[
              { key: "location_name", label: t("EquipmentsPage.name") },
              {
                key: "location_data.comment",
                label: t("EquipmentsPage.descriptions")
              },
              {
                key: "location_data.street",
                label: t("EquipmentsPage.address")
              },
              { key: "location_data.type", label: t("EquipmentsPage.type") },
              {
                key: "actions",
                label: t("EquipmentsPage.actions"),
                align: "right",
                noSort: true
              }
            ]}
            defaultSort={["location_name", "asc"]}
            onHeaderSort={setLocationsName}
            data={locationsName}
            renderRow={row => [
              <>
                <Box sx={{ display: "flex", alignItems: "baseline" }}>
                  {row.shared_data &&
                    ((row.shared_data.owner !== "" &&
                      row.shared_data.owner !== null &&
                      row.shared_data.owner !== undefined) ||
                      (row.shared_data.shared &&
                        row.shared_data.shared.length > 0)) && (
                      <Tooltip title={t("action.shared").toString()}>
                        <Users size={12} style={{ marginRight: "6px" }} />
                      </Tooltip>
                    )}
                  <Box>
                    <strong>{row.location_name}</strong>{" "}
                    {row.shared_data &&
                      row.shared_data.alias !== "" &&
                      row.shared_data.alias !== null &&
                      row.shared_data.alias !== undefined && (
                        <Typography color="textSecondary" variant="caption">
                          {`(${row.shared_data.alias})`}
                        </Typography>
                      )}
                  </Box>
                </Box>
              </>,
              <>
                {row["location_data"].comment === "" ? (
                  <Typography component="div" variant="caption">
                    {t("EquipmentsPage.noComment")}
                  </Typography>
                ) : (
                  <strong>{row["location_data"].comment}</strong>
                )}
              </>,
              <>
                {row["location_data"].state === "" &&
                row["location_data"].city === "" ? (
                  <Typography component="div" variant="caption">
                    {t("EquipmentsPage.noAddress")}
                  </Typography>
                ) : (
                  <>
                    <Typography component="div" variant="caption">
                      <strong>{`${row["location_data"].street}, ${row["location_data"].number}`}</strong>
                    </Typography>
                    <Typography color="textSecondary" variant="caption">
                      {`${cepFormatter(row["location_data"].zip_code)}, ${
                        row["location_data"].city
                      } - ${row["location_data"].state}`}
                    </Typography>
                  </>
                )}
              </>,
              <>
                <Typography component="div" variant="caption">
                  <strong>
                    {t(`equipmentType.${row["location_data"].type}`)}
                  </strong>
                </Typography>
              </>,
              <>
                <DataTableActions
                  actions={[
                    {
                      tooltip: t("action.edit"),
                      icon: Edit,
                      onClick: () => openFormDialog(row)
                    },
                    {
                      tooltip: t("action.remove"),
                      icon: MinusCircle,
                      disabled: Boolean(row["shared_data"]?.owner),
                      onClick: () => handleRemove(row["location_name"])
                    }
                  ]}
                />
              </>
            ]}
            hideColumnsXs={[1, 2, 3, 4]}
            page={page}
            onPageChange={pageValue => requestData(search, pageValue, pageSize)}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            totalPages={paginator.totalPages}
            totalItems={paginator.totalItems}
            isLoading={isLoading}
          />
        </PageSection>
      </InnerPageLayout>
      <AliasFormDialog
        aliasType="equipment"
        open={aliasFormOpen}
        item={itemSelected}
        currentAlias={
          itemSelected.shared_data ? itemSelected.shared_data.alias : ""
        }
        setOpen={setAliasFormOpen}
        onRequestCompleted={locationName =>
          onRequestCompleted(false, locationName)
        }
      />
      <EquipmentsFormDialog
        locationsName={itemSelected?.location_name || ""}
        open={isFormDialogOpen}
        setOpen={setIsFormDialogOpen}
        onRequestCompleted={(isCreating, locationName) =>
          onRequestCompleted(isCreating, locationName)
        }
      />
      <Dialog
        maxWidth="xs"
        className="alertDialog"
        open={isConfirmCreateCameraOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setIsConfirmCreateCameraOpen(false);
          }
        }}
      >
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Box sx={{ textAlign: "left", mb: 2 }}>
              <CheckCircle color={theme.palette.success.main} size={34} />
            </Box>
            <div>
              <Box sx={{ mb: 2 }}>
                <Typography variant="h4">
                  <strong>{t("EquipmentsPage.equipmentCreated")}</strong>
                </Typography>
              </Box>
            </div>
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ justifyContent: "center" }}>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick() {
                setItemSelected(defaultEquipmentValues);
                setItemCamSelected("");
                setIsConfirmCreateCameraOpen(false);
              }
            }}
          >
            {t("action.cancel")}
          </Button>
          <Button
            customProps={{
              color: "secondary",
              onClick() {
                setIsConfirmCreateCameraOpen(false);
                openFormDialog({
                  ["location_name"]: itemCamSelected,
                  ["customer_id"]: sessionUser?.customer_id ?? "",
                  ["location_data"]: {
                    code: "",
                    type: "",
                    ["zip_code"]: "",
                    number: "",
                    ["city"]: "",
                    street: "",
                    ["place_type"]: "",
                    district: "",
                    comment: "",
                    complement: "",
                    ["state"]: "",
                    longitude: "",
                    latitude: ""
                  },
                  cameras: [],
                  ["shared_data"]: {
                    ["owner_name"]: "",
                    owner: "",
                    alias: "",
                    shared: []
                  }
                });
              }
            }}
          >
            {t("action.continue")}
          </Button>
        </DialogActions>
      </Dialog>
    </DefaultPageLayout>
  );
};

export default EquipmentsPage;
