import Bus from "icons/Bus";
import Car from "icons/Car";
import useFlowDashboardAPI, {
  HourRegister,
  MostMonitoredClass,
  RankingPlate,
  WeekRegister
} from "api/FlowDashboardAPI";
import Truck from "icons/Truck";
import Pages from "enums/Pages";
import PlateGraphByFrequency, {
  FrequencyPlateData
} from "./PlateGraphByFrequency";
import { orderBy } from "lodash";
import Unknown from "icons/Unknown";
import Motorcycle from "icons/Motorcycle";
import TableCaptures from "./TableCaptures";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import { useLocale } from "contexts/LocaleContext";
import { dateTimeToString } from "utils/DateFunctions";
import { FC, useCallback, useEffect, useState } from "react";
import { usePageLocation } from "contexts/PageLocationContext";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import { FormFlowDashboardFilter } from "./FlowDashboardFilter";
import { useFlowDashboardFilter } from "./FlowDashboardFilterContext";
import CapturedVehicleGraphByHour from "./CapturedVehicleGraphByHour";
import CapturedVehicleGraphByDayOfWeek from "./CapturedVehicleGraphByDayOfWeek";
import PageSection from "components/PageSection/PageSection";

const CapturedVehicleGraphs: FC = () => {
  const FlowDashboardAPI = useFlowDashboardAPI();
  const { t } = useTranslation();
  const { language } = useLocale();
  const { sessionUser } = useAuth();
  const { errorHandler } = useErrorHandler();
  const [isFetchingRegisters, setIsFetchingRegisters] = useState(false);
  const [isFetchingPlates, setIsFetchingPlates] = useState(false);
  const [isFetchingEquipments, setIsFetchingEquipments] = useState(false);
  const { setPageTitle, setLocation } = usePageLocation();
  const { getFilterData, filterData } = useFlowDashboardFilter();
  const [plateFrequency, setPlateFrequency] = useState<FrequencyPlateData[]>(
    []
  );
  const [captureScoreWeek, setCaptureScoreWeek] = useState<WeekRegister[]>([]);
  const [captureScoreHour, setCaptureScoreHour] = useState<HourRegister[]>([]);
  const [mostCapturedClass, setMostCapturedClass] = useState<RankingPlate[]>(
    []
  );
  const [plateRanking, setPlateRanking] = useState<RankingPlate[]>([]);
  const [equipmentsDetection, setEquipmentsDetection] = useState<
    RankingPlate[]
  >([]);

  const isEmpty = (values: MostMonitoredClass): boolean =>
    values.unknown === 0 &&
    values.motorcycle === 0 &&
    values.truck === 0 &&
    values.bus === 0 &&
    values.car === 0;

  const changeWeekName = (values: WeekRegister[]) => {
    const mappedData = values.map(day => ({
      ...day,
      weekDayName: t(`form.weekDays.${day.weekday}`).slice(0, 3)
    }));
    setCaptureScoreWeek(mappedData || []);
  };

  const requestRegisterData = useCallback(
    async (filter: FormFlowDashboardFilter) => {
      if (!sessionUser?.["customer_id"]) return;
      const customerId = sessionUser["customer_id"];
      setIsFetchingRegisters(true);
      try {
        const registerResponse = await FlowDashboardAPI.registerFlow({
          ["customer_id"]: customerId,
          ["initial_date"]: dateTimeToString(
            filter.startDate as Date,
            filter.startTime as Date
          ),
          ["final_date"]: dateTimeToString(
            filter.endDate as Date,
            filter.endTime as Date
          ),
          equipments: filter.equipments,
          grouping: "all"
        });

        const vehicleClassScore = registerResponse.registers.vehicle_class;

        let vehicleClassArray = [
          {
            plate: t("MonitoredVehiclesDashboardPage.unknown"),
            matches: vehicleClassScore.unknown,
            icon: <Unknown color="#768299" />
          },
          {
            plate: t("MonitoredVehiclesDashboardPage.motorcycle"),
            matches: vehicleClassScore.motorcycle,
            icon: <Motorcycle color="#768299" />
          },
          {
            plate: t("MonitoredVehiclesDashboardPage.truck"),
            matches: vehicleClassScore.truck,
            icon: <Truck color="#768299" />
          },
          {
            plate: t("MonitoredVehiclesDashboardPage.bus"),
            matches: vehicleClassScore.bus,
            icon: <Bus color="#768299" />
          },
          {
            plate: t("MonitoredVehiclesDashboardPage.car"),
            matches: vehicleClassScore.car,
            icon: <Car dimensions="0 0 24 24" color="#768299" />
          }
        ];

        vehicleClassArray = orderBy(
          vehicleClassArray,
          isEmpty(vehicleClassScore)
            ? ["plate", "matches"]
            : ["matches", "plate"],
          isEmpty(vehicleClassScore) ? ["asc", "desc"] : ["desc", "asc"]
        );

        setMostCapturedClass(vehicleClassArray || []);

        changeWeekName(registerResponse.registers.week);
        setCaptureScoreHour(registerResponse.registers.hour);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsFetchingRegisters(false);
      }
    },
    [sessionUser]
  );

  const requestPlateData = useCallback(
    async (filter: FormFlowDashboardFilter) => {
      if (!sessionUser?.["customer_id"]) return;
      const customerId = sessionUser["customer_id"];
      setIsFetchingPlates(true);
      try {
        const platesResponse = await FlowDashboardAPI.mostRegisteredPlates({
          ["customer_id"]: customerId,
          ["initial_date"]: dateTimeToString(
            filter.startDate as Date,
            filter.startTime as Date
          ),
          ["final_date"]: dateTimeToString(
            filter.endDate as Date,
            filter.endTime as Date
          ),
          equipments: filter.equipments,
          ranking: filter.ranking,
          histogram: true
        });

        const platesFrequencyMapping: FrequencyPlateData[] = [
          {
            name: "1",
            total: platesResponse.registers.histogram.bin1.length,
            plates: platesResponse.registers.histogram.bin1
          },
          {
            name: "5",
            total: platesResponse.registers.histogram.bin5.length,
            plates: platesResponse.registers.histogram.bin5
          },
          {
            name: "10",
            total: platesResponse.registers.histogram.bin10.length,
            plates: platesResponse.registers.histogram.bin10
          },
          {
            name: "50",
            total: platesResponse.registers.histogram.bin50.length,
            plates: platesResponse.registers.histogram.bin50
          },
          {
            name: "100",
            total: platesResponse.registers.histogram.bin100.length,
            plates: platesResponse.registers.histogram.bin100
          },
          {
            name: "500",
            total: platesResponse.registers.histogram.bin500.length,
            plates: platesResponse.registers.histogram.bin500
          },
          {
            name: "1000",
            total: platesResponse.registers.histogram.bin1000.length,
            plates: platesResponse.registers.histogram.bin1000
          },
          {
            name: "10000+",
            total: platesResponse.registers.histogram.bin10000Plus.length,
            plates: platesResponse.registers.histogram.bin10000Plus
          }
        ];

        setPlateFrequency(platesFrequencyMapping);

        const platesMapping: RankingPlate[] = [];

        platesResponse.registers.plateScore.forEach(plate =>
          platesMapping.push({
            plate: plate.plate,
            matches: Number(plate.matches)
          })
        );

        const platesArray = orderBy(
          platesMapping,
          ["matches", "plate"],
          ["desc", "asc"]
        );

        setPlateRanking(platesArray || []);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsFetchingPlates(false);
      }
    },
    [sessionUser]
  );

  const requestEquipmentData = useCallback(
    async (filter: FormFlowDashboardFilter) => {
      if (!sessionUser?.["customer_id"]) return;
      const customerId = sessionUser["customer_id"];
      setIsFetchingEquipments(true);
      try {
        const equipmentsResponse =
          await FlowDashboardAPI.mostRegisteredEquipments({
            ["customer_id"]: customerId,
            ["initial_date"]: dateTimeToString(
              filter.startDate as Date,
              filter.startTime as Date
            ),
            ["final_date"]: dateTimeToString(
              filter.endDate as Date,
              filter.endTime as Date
            )
          });

        const equipmentsMapping: RankingPlate[] = [];

        equipmentsResponse.registers.forEach(equipments =>
          equipmentsMapping.push({
            plate: equipments.name,
            matches: Number(equipments.matches)
          })
        );

        const equipmentsArray = orderBy(
          equipmentsMapping,
          ["matches", "plate"],
          ["desc", "asc"]
        );

        setEquipmentsDetection(equipmentsArray);
      } catch (error) {
        errorHandler({ error });
      } finally {
        setIsFetchingEquipments(false);
      }
    },
    [sessionUser]
  );

  useEffect(() => {
    requestRegisterData(getFilterData());
    requestPlateData(getFilterData());
    requestEquipmentData(getFilterData());
  }, [requestRegisterData, requestPlateData, filterData, language]);

  useEffect(() => {
    setPageTitle(t("windowTitle.flowDashboard"));
    setLocation([
      {
        label: t("DefaultPageLayout.flowDashboard"),
        page: Pages.FLOW_DASHBOARD
      }
    ]);
  }, [t, Pages]);

  return (
    <>
      <CapturedVehicleGraphByDayOfWeek
        title={t("FlowDashboardPage.capturesByDaysOfTheWeek")}
        data={captureScoreWeek}
        isFetching={isFetchingRegisters}
      />
      <CapturedVehicleGraphByHour
        title={t("FlowDashboardPage.capturesByHoursOfTheDay")}
        data={captureScoreHour}
        isFetching={isFetchingRegisters}
      />
      <PageSection
        title={t(
          "MonitoredVehiclesDashboardPage.rankingOfCapturesByVehicleClass"
        )}
        variant="h5"
      >
        <TableCaptures
          title={t("MonitoredVehiclesDashboardPage.class")}
          data={mostCapturedClass}
          isFetching={isFetchingRegisters}
        />
      </PageSection>
      <PageSection title={t("FlowDashboardPage.flowDashboardIntelligence")}>
        <PageSection title={t("FlowDashboardPage.plateRanking")} variant="h5">
          <TableCaptures
            title={t("FlowDashboardPage.plate")}
            data={plateRanking}
            isFetching={isFetchingPlates}
          />
        </PageSection>
        <PageSection
          title={t("FlowDashboardPage.equipmentDetections")}
          variant="h5"
        >
          <TableCaptures
            title={t("MonitoredVehiclesDashboardPage.equipment")}
            data={equipmentsDetection}
            isFetching={isFetchingEquipments}
          />
        </PageSection>
        <PlateGraphByFrequency
          title={t("FlowDashboardPage.plateFrequency")}
          data={plateFrequency}
          isFetching={isFetchingPlates}
        />
      </PageSection>
    </>
  );
};

export default CapturedVehicleGraphs;
