import {
  Box,
  Grid,
  styled,
  TextField,
  Typography,
  Autocomplete,
  Tooltip
} from "@mui/material";
import Button from "components/Button";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import InputField from "components/InputField";
import { Controller, useForm } from "react-hook-form";
import useCameraAPI from "api/CameraAPI";
import TimePickerField from "components/TimePickerField";
import DatePickerField from "components/DatePickerField";
import useLocationAPI from "api/LocationAPI";
import { setHours, setMinutes, subMonths } from "date-fns";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import Drawer from "components/Drawer";
import FormLabel from "components/FormLabel";
import { LocationFilter } from "api/LocationAPI";

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormVehicleOverviewFilter = {
  plate: string;
  startDate: string | Date;
  endDate: string | Date;
  startTime: string | Date;
  endTime: string | Date;
  cameras?: string[];
  locations?: string[];
};

const defaultDate = new Date();

export const defaultValues: FormVehicleOverviewFilter = {
  plate: "",
  startDate: setMinutes(setHours(defaultDate.setDate(1), 0), 0),
  endDate: new Date(),
  startTime: setMinutes(setHours(defaultDate.setDate(1), 0), 0),
  endTime: new Date(),
  cameras: undefined,
  locations: undefined
};

type Props = {
  open: boolean;
  plate: string;
  setOpen: (isOpen: boolean) => void;
  setFilterData: (data: FormVehicleOverviewFilter) => void;
};

const VehicleOverviewFilter: FC<Props> = props => {
  const { t } = useTranslation();
  const { plate, open, setOpen, setFilterData } = props;
  const CameraAPI = useCameraAPI();
  const { sessionUser } = useAuth();
  const LocationAPI = useLocationAPI();
  const { errorHandler } = useErrorHandler();
  const [cameras, setCameras] = useState<string[]>([]);
  const [locationsName, setLocationsName] = useState<string[]>([]);
  const [locationsComments, setLocationsComments] = useState<LocationFilter[]>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedCameras, setSelectedCameras] = useState<string[]>([]);
  const [selectedEquipments, setSelectedEquipments] = useState<string[]>([]);
  const { control, formState, handleSubmit, reset, setValue } =
    useForm<FormVehicleOverviewFilter>({
      mode: "onChange",
      defaultValues: {
        ...defaultValues,
        plate
      }
    });

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      const [locationResponse, camerasResponse] = await Promise.all([
        LocationAPI.listAll(sessionUser.customer_id),
        CameraAPI.listAll({ customerId: sessionUser.customer_id })
      ]);
      setCameras(camerasResponse.data.map(camera => camera.camera_name) || []);
      setLocationsName(
        locationResponse.data.map(location => location.location_name) || []
      );
      setLocationsComments([
        t("form.all"),
        ...locationResponse.data.map(location => ({
          ["location_name"]: location["location_name"],
          comment: location["location_data"].comment
        }))
      ]);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestData();
  }, [requestData]);

  const clear = () => {
    reset(defaultValues);
    const d = setMinutes(setHours(new Date().setDate(1), 0), 0);
    setValue(
      "startDate",
      subMonths(setMinutes(setHours(d.setDate(1), 0), 0), 1)
    );
    setValue("startTime", setMinutes(setHours(d.setDate(1), 0), 0));
    setValue("endDate", new Date());
    setValue("endTime", new Date());
    setSelectedEquipments([]);
    setSelectedCameras([]);
  };

  const onSubmit = async (data: FormVehicleOverviewFilter) => {
    const newData = { ...data };

    newData.startDate = new Date(newData.startDate);
    newData.endDate = new Date(newData.endDate);
    newData.startTime = new Date(newData.startTime);
    newData.endTime = new Date(newData.endTime);
    newData.locations =
      selectedEquipments.length > 0 ? selectedEquipments : undefined;
    newData.cameras = selectedCameras.length > 0 ? selectedCameras : undefined;

    setFilterData(newData);
    setOpen(false);
  };

  return (
    <Drawer open={open} setOpen={setOpen} title={t("action.filter")}>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={8}>
              <Controller
                name="startDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("VehicleOverviewPage.startDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="startTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={8}>
              <Controller
                name="endDate"
                rules={{
                  required: t("form.requiredField").toString()
                }}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePickerField
                    required
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                    label={t("VehicleOverviewPage.endDateTime")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Controller
                name="endTime"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <TimePickerField
                    required
                    disabled={isLoading}
                    field={{ ...field }}
                    fieldState={fieldState}
                    sx={{ mt: 3 }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="plate"
                control={control}
                rules={{
                  required: t("form.requiredField").toString()
                }}
                render={({ field, fieldState }) => (
                  <InputField
                    uppercase
                    gutter={false}
                    label={t("VehicleOverviewPage.plate")}
                    field={{ ...field }}
                    fieldState={fieldState}
                    customProps={{
                      required: true,
                      inputProps: {
                        maxLength: 7
                      }
                    }}
                  />
                )}
              />
              <Typography variant="caption" align="left" color="primary.light">
                {t("VehicleOverviewPage.tooltipPlate")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("VehicleOverviewPage.equipments")}</FormLabel>
              <Autocomplete
                multiple
                filterSelectedOptions
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={locationsName}
                getOptionLabel={option => option}
                onChange={(event, newValues) => {
                  event.preventDefault();
                  setSelectedEquipments(newValues);
                }}
                noOptionsText={t("form.noOptions")}
                value={selectedEquipments}
                size="small"
                renderOption={(props, values) => {
                  if (locationsComments.length > 0) {
                    const tooltipComment = values.includes(t("form.all"))
                      ? ""
                      : locationsComments[
                          locationsComments.findIndex(
                            x => x.location_name === values
                          )
                        ].comment;
                    return (
                      <Tooltip
                        title={`${tooltipComment}`}
                        leaveDelay={0}
                        disableInteractive
                      >
                        <li {...props}>{values}</li>
                      </Tooltip>
                    );
                  }
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t("VehicleOverviewPage.equipmentHint")}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormLabel>{t("VehicleOverviewPage.cameras")}</FormLabel>
              <Autocomplete
                multiple
                filterSelectedOptions
                openText={t("action.open")}
                closeText={t("action.close")}
                clearText={t("action.clear")}
                options={cameras}
                getOptionLabel={option => option}
                onChange={(event, newValues) => {
                  event.preventDefault();
                  setSelectedCameras(newValues);
                }}
                noOptionsText={t("form.noOptions")}
                value={selectedCameras}
                size="small"
                renderInput={params => (
                  <TextField
                    {...params}
                    placeholder={t("VehicleOverviewPage.camerasHint")}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Content>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: () => clear()
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              disabled: !formState.isValid || isLoading,
              type: "submit"
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default VehicleOverviewFilter;
