import { Box, Chip, Grid, MenuItem, styled, Typography } from "@mui/material";
import Car from "icons/Car";
import useMapsAPI from "api/MapsAPI";
import useToolsAPI, { City, State } from "api/ToolsAPI";
import useLocationAPI, { CameraItem } from "api/LocationAPI";
import Button from "components/Button";
import { useIsMount } from "hooks/useIsMount";
import { useTranslation } from "react-i18next";
import InputField from "components/InputField";
import { useAuth } from "contexts/AuthContext";
import RegexPatterns from "utils/RegexPatterns";
import { MapPin, Plus, Smartphone, Video } from "react-feather";
import SelectField from "components/SelectField";
import { Controller, useForm } from "react-hook-form";
import EquipmentsAddressMap from "./EquipmentsAddressMap";
import { FC, useCallback, useEffect, useState } from "react";
import snackNotification from "components/SnackNotification";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import CamerasFormDialog, { CameraForm } from "../Cameras/CamerasFormDialog";
import FormDialog from "components/FormDialog";
import FormLabel from "components/FormLabel";

const CamerasBox = styled(Box)(({ theme }) => ({
  border: "1px solid #e0e0e0",
  borderRadius: theme.shape.borderRadius,
  minHeight: 40,
  padding: 5,
  "& .MuiChip-root": {
    marginRight: 3
  }
}));

export type MapAddress = {
  zipCode: string;
  latitude: string;
  longitude: string;
  city: string;
  number: string;
  street: string;
  state: string;
};

type EquipmentForm = {
  customerId: string;
  locationName: string;
  oldLocationName: string | undefined;
  comment: string;
  type: string;
  zipCode: string;
  latitude: string;
  longitude: string;
};

const defaultValues = {
  customerId: "",
  locationName: "",
  oldLocationName: "",
  type: "",
  comment: "",
  zipCode: "",
  latitude: "",
  longitude: ""
};

export type CameraSelected = string | CameraItem;

type Props = {
  locationsName: string;
  open: boolean;
  setOpen: (open: boolean) => void;
  onRequestCompleted: (isCreating: boolean, locationsName: string) => void;
};

const EquipmentsFormDialog: FC<Props> = ({
  locationsName,
  open,
  setOpen,
  onRequestCompleted
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [openMap, setOpenMap] = useState<boolean>(false);
  const [isFixed, setIsFixed] = useState<boolean>(false);
  const { t } = useTranslation();
  const isCreating = locationsName === "";
  const MapsAPI = useMapsAPI();
  const ToolsAPI = useToolsAPI();
  const LocationAPI = useLocationAPI();
  const isMount = useIsMount();
  const { sessionUser } = useAuth();
  const [camerasSelected, setCamerasSelected] = useState<CameraSelected[]>([]);
  const [cities, setCities] = useState<City[]>([]);
  const [newCity, setNewCity] = useState<string>("");
  const [newStreet, setNewStreet] = useState<string>("");
  const [newNumber, setNewNumber] = useState<string>("");
  const [states, setStates] = useState<State[]>([]);
  const [newState, setNewState] = useState<string>("");
  const [cameraDialogOpen, setCameraDialogOpen] = useState(false);
  const [equipment, setEquipment] = useState<EquipmentForm>(defaultValues);
  const { errorHandler } = useErrorHandler();

  const {
    control,
    formState,
    handleSubmit,
    reset,
    setValue,
    trigger,
    getValues
  } = useForm<EquipmentForm>({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues
  });

  const openFormCameraDialog = () => {
    setCameraDialogOpen(true);
  };

  const onSetNewAddress = async (address: MapAddress) => {
    setEquipment({
      customerId: getValues("customerId"),
      locationName: getValues("locationName"),
      oldLocationName: getValues("locationName"),
      comment: getValues("comment"),
      type: getValues("type"),
      zipCode: address.zipCode,
      latitude: address.latitude,
      longitude: address.longitude
    });
    setNewState(address.state);
    setNewNumber(address.number);
    setNewStreet(address.street);
    setNewCity(address.city);
  };

  const requestData = useCallback(async () => {
    if (!sessionUser) return;
    setIsLoading(true);
    try {
      if (!isCreating) {
        const equipmentResponse = await LocationAPI.getByName(
          sessionUser["customer_id"],
          locationsName
        );
        setNewCity(equipmentResponse["location_data"]["city"]);
        setNewNumber(equipmentResponse["location_data"].number);
        setNewStreet(equipmentResponse["location_data"].street);
        setNewState(equipmentResponse["location_data"]["state"]);
        setEquipment({
          customerId: equipmentResponse["customer_id"],
          locationName: equipmentResponse["location_name"],
          oldLocationName: equipmentResponse["location_name"],
          comment: equipmentResponse["location_data"].comment,
          type: equipmentResponse["location_data"].type,
          zipCode: equipmentResponse["location_data"]["zip_code"],
          latitude: equipmentResponse["location_data"].latitude,
          longitude: equipmentResponse["location_data"].longitude
        });
        setIsFixed(equipmentResponse["location_data"].type === "fixed");
        setCamerasSelected(equipmentResponse.cameras);
        await trigger();
      }
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  }, [isCreating, reset, sessionUser, t, locationsName]);

  const getCountry = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    try {
      const countryResponse = await ToolsAPI.getCountry("Brasil");
      setStates(countryResponse.data[0].states);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const getCities = async (state: string) => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    try {
      const citiesResponse = await ToolsAPI.getCities("Brasil", state);
      setCities(citiesResponse.data[0].cities);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const handleOpenMap = () => {
    setOpenMap(true);
  };

  const getLongitudeAndLatitude = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    try {
      const translateResponse = await MapsAPI.translateAddress(
        sessionUser["customer_id"],
        newStreet,
        newNumber,
        newCity
      );
      setValue("latitude", translateResponse.latitude);
      setValue("longitude", translateResponse.longitude);
      await trigger();
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      requestData();
      getCountry();
    }
  }, [requestData, open]);

  useEffect(() => {
    reset(equipment);
  }, [setValue, equipment]);

  useEffect(() => {
    if (!isMount && newState) {
      getCities(newState);
    }
  }, [newState]);

  const onBlurLongitudeAndLatitude = () => {
    if (newCity && newStreet && newNumber) {
      getLongitudeAndLatitude();
    }
  };

  useEffect(() => {
    if (!open) {
      setEquipment(defaultValues);
      reset(defaultValues);
      setNewCity("");
      setNewState("");
      setNewStreet("");
      setNewNumber("");
      setCities([]);
      setOpenMap(false);
    }
  }, [open]);

  const onSubmit = async (data: EquipmentForm) => {
    if (!sessionUser?.["customer_id"]) return;
    setIsLoading(true);
    const newData = { ...data };
    try {
      const cameras = camerasSelected.filter(
        camera => typeof camera !== "string"
      ) as CameraItem[];
      if (isCreating) {
        newData.customerId = sessionUser["customer_id"];
        await LocationAPI.create(
          newData,
          newCity,
          newState,
          newStreet,
          newNumber
        );
      } else {
        if (newData.type === "mobile" || newData.type === "device") {
          newData.latitude = "0.000000";
          newData.longitude = "0.000000";
          newData.zipCode = "";
        }

        if (newData.oldLocationName === newData.locationName) {
          newData.oldLocationName = undefined;
        }
        await LocationAPI.update(
          newData,
          newCity,
          newState,
          newStreet,
          newNumber,
          cameras.length > 0 ? cameras : []
        );
        snackNotification.success(t("EquipmentsPage.equipmentUpdated"));
      }
      onRequestCompleted(isCreating, newData.locationName);
      setOpen(false);
      setCamerasSelected([]);
      setIsFixed(false);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setIsLoading(false);
    }
  };

  const toggleFixedType = () => {
    setNewCity("");
    setNewState("");
    setNewStreet("");
    setNewNumber("");
    setCities([]);
    setIsFixed(false);
    setOpenMap(false);
  };

  const removeCamera = (cameraName: string) => {
    setCamerasSelected(
      camerasSelected.filter(camera => {
        if (typeof camera !== "string") {
          return camera["camera_data"]["camera_name"] !== cameraName;
        }
        return true;
      })
    );
  };

  const onCamerasFormSave = (camera?: CameraForm) => {
    if (!camera) return;
    setCamerasSelected([
      ...camerasSelected,
      {
        ["camera_data"]: {
          ["camera_name"]: camera.cameraName,
          active: camera.active,
          side: camera.side,
          ["serial_number"]: camera.serialNumber,
          ["request_image_recognized"]: camera.requestImageRecognized,
          ["request_image_unrecognized"]: camera.requestImageUnrecognized
        }
      }
    ]);
  };

  return (
    <>
      <FormDialog
        onConfirm={handleSubmit(onSubmit)}
        open={open}
        setOpen={setOpen}
        isLoading={isLoading}
        dirty={formState.isDirty}
        confirmDisabled={
          !formState.isValid ||
          (newCity === "" && isFixed) ||
          (newNumber === "" && isFixed) ||
          (newState === "" && isFixed)
        }
        confirmText={t("action.apply")}
        title={
          isCreating
            ? t("EquipmentsPage.createANewEquipment")
            : t("EquipmentsPage.editEquipment")
        }
      >
        <Grid container rowSpacing={1} columnSpacing={2}>
          <Grid item xs={12}>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body1" align="left" color="textSecondary">
                <strong>{t("EquipmentsPage.basicInfo")}</strong>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} sm={7}>
            <Controller
              name="locationName"
              control={control}
              rules={{
                required: t("form.requiredField").toString()
              }}
              render={({ field, fieldState }) => (
                <InputField
                  label={t("EquipmentsPage.name")}
                  customProps={{
                    inputProps: {
                      maxLength: 100
                    },
                    required: true,
                    disabled: isLoading
                  }}
                  field={{ ...field }}
                  fieldState={fieldState}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} sm={5}>
            <Controller
              name="type"
              control={control}
              rules={{
                required: t("form.requiredField").toString()
              }}
              render={({ field }) => (
                <SelectField
                  {...field}
                  required
                  label={t("EquipmentsPage.type")}
                  disabled={isLoading}
                  onChange={event => {
                    field.onChange(event.target.value as string);
                  }}
                >
                  <MenuItem value="" onClick={() => toggleFixedType()}>
                    {t("EquipmentsPage.typeHint")}
                  </MenuItem>
                  <MenuItem value="fixed" onClick={() => setIsFixed(true)}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {t("equipmentType.fixed")}
                      <Video
                        size={20}
                        color="#768299"
                        style={{ marginLeft: "8px" }}
                      />
                    </div>
                  </MenuItem>
                  <MenuItem value="mobile" onClick={() => toggleFixedType()}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {t("equipmentType.mobile")}
                      <Box
                        sx={{
                          "& svg": { width: "0.7em", height: "0.7em" },
                          marginLeft: "8px"
                        }}
                      >
                        <Car color="#768299" />
                      </Box>
                    </div>
                  </MenuItem>
                  <MenuItem value="device" onClick={() => toggleFixedType()}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {t("equipmentType.device")}
                      <Smartphone
                        size={20}
                        color="#768299"
                        style={{ marginLeft: "8px" }}
                      />
                    </div>
                  </MenuItem>
                </SelectField>
              )}
            />
          </Grid>
          {!isCreating && (
            <>
              <Grid item xs={12} sm={8} sx={{ marginBottom: "30px" }}>
                <FormLabel>{t("CaptureReportPage.cameras")}</FormLabel>
                <CamerasBox>
                  {camerasSelected.map((camera, idx) => {
                    if (typeof camera === "string") {
                      return <Chip key={idx} size="small" label={camera} />;
                    }
                    return (
                      <Chip
                        key={idx}
                        size="small"
                        label={camera["camera_data"]["camera_name"]}
                        onDelete={() =>
                          removeCamera(camera["camera_data"]["camera_name"])
                        }
                      />
                    );
                  })}
                </CamerasBox>
              </Grid>
              <Grid
                item
                xs={12}
                sm={4}
                sx={{ marginTop: "26px", marginBottom: "30px" }}
              >
                <Button
                  customProps={{
                    color: "secondary",
                    startIcon: <Plus />,
                    sx: { width: "100%" },
                    onClick: () => openFormCameraDialog()
                  }}
                >
                  {t("CamerasPage.newCameras")}
                </Button>
              </Grid>
            </>
          )}
          <Grid item xs={12} sm={12}>
            <Controller
              name="comment"
              control={control}
              render={({ field, fieldState }) => (
                <InputField
                  label={t("EquipmentsPage.descriptions")}
                  customProps={{
                    disabled: isLoading
                  }}
                  field={{ ...field }}
                  fieldState={fieldState}
                />
              )}
            />
          </Grid>
          {isFixed && (
            <>
              <Grid item xs={12}>
                <Box sx={{ my: 2 }}>
                  <Typography
                    variant="body1"
                    align="left"
                    color="textSecondary"
                  >
                    <strong>{t("EquipmentsPage.localizationInfo")}</strong>
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={8}>
                <InputField
                  label={t("EquipmentsPage.address")}
                  customProps={{
                    inputProps: {
                      maxLength: 100
                    },
                    required: Boolean(isFixed),
                    value: newStreet,
                    onChange(event) {
                      setNewStreet(event.target.value);
                    },
                    onBlur() {
                      onBlurLongitudeAndLatitude();
                    },
                    disabled: isLoading
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <InputField
                  label={t("EquipmentsPage.addressNumber")}
                  customProps={{
                    inputProps: {
                      maxLength: 10
                    },
                    required: Boolean(isFixed),
                    value: newNumber,
                    onBlur() {
                      onBlurLongitudeAndLatitude();
                    },
                    onChange(event) {
                      setNewNumber(event.target.value);
                    },
                    disabled: isLoading
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectField
                  required
                  label={t("EquipmentsPage.state")}
                  value={newState}
                  onChange={event => {
                    setNewState(event.target.value as string);
                  }}
                >
                  {states.map(option => (
                    <MenuItem key={option.name} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <SelectField
                  required
                  label={t("EquipmentsPage.city")}
                  value={newCity}
                  onChange={event => {
                    setNewCity(event.target.value as string);
                  }}
                  onClose={onBlurLongitudeAndLatitude}
                >
                  {cities.map((option, index) => (
                    <MenuItem key={index} value={option.name}>
                      {option.name}
                    </MenuItem>
                  ))}
                </SelectField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="zipCode"
                  control={control}
                  rules={{
                    required: t("form.requiredField").toString()
                  }}
                  render={({ field, fieldState }) => (
                    <InputField
                      mask="99999-999"
                      label={t("EquipmentsPage.zipCode")}
                      customProps={{
                        disabled: isLoading,
                        required: true
                      }}
                      field={{ ...field }}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="latitude"
                  control={control}
                  rules={{
                    required: t("form.requiredField").toString(),
                    pattern: {
                      value: RegexPatterns.LAT,
                      message: t("form.invalidFormat")
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputField
                      label={t("EquipmentsPage.latitude")}
                      customProps={{
                        inputProps: {
                          maxLength: 10
                        },
                        required: Boolean(isFixed),
                        inputMode: "numeric",
                        disabled: isLoading
                      }}
                      field={{ ...field }}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Controller
                  name="longitude"
                  control={control}
                  rules={{
                    required: t("form.requiredField").toString(),
                    pattern: {
                      value: RegexPatterns.LON,
                      message: t("form.invalidFormat")
                    }
                  }}
                  render={({ field, fieldState }) => (
                    <InputField
                      label={t("EquipmentsPage.longitude")}
                      customProps={{
                        inputProps: {
                          maxLength: 10
                        },
                        required: Boolean(isFixed),
                        inputMode: "numeric",
                        disabled: isLoading
                      }}
                      field={{ ...field }}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <Button
                  customProps={{
                    color: "secondary",
                    startIcon: <MapPin />,
                    sx: { mt: "26px", width: "100%" },
                    onClick: () => handleOpenMap()
                  }}
                >
                  {t("EquipmentsPage.selectAddress")}
                </Button>
              </Grid>
              {openMap && (
                <EquipmentsAddressMap
                  setOpenMap={setOpenMap}
                  onSetNewAddress={onSetNewAddress}
                />
              )}
            </>
          )}
        </Grid>
      </FormDialog>
      <CamerasFormDialog
        cameraName=""
        locationName={equipment.locationName}
        open={cameraDialogOpen}
        setOpen={setCameraDialogOpen}
        persist={false}
        onSave={onCamerasFormSave}
      />
    </>
  );
};

export default EquipmentsFormDialog;
