import {
  Box,
  Grid,
  styled,
  TextField,
  Typography,
  Autocomplete,
  CircularProgress,
  Divider,
  Tooltip
} from "@mui/material";
import { AxiosError } from "axios";
import { endOfDay, startOfDay } from "date-fns";
import Button from "components/Button";
import useLocationAPI from "api/LocationAPI";
import useToolsAPI from "api/ToolsAPI";
import { useTranslation } from "react-i18next";
import { useAuth } from "contexts/AuthContext";
import InputField from "components/InputField";
import { Controller, useForm } from "react-hook-form";
import DatePickerField from "components/DatePickerField";
import TimePickerField from "components/TimePickerField";
import { FC, useCallback, useEffect, useState } from "react";
import { useErrorHandler } from "contexts/ErrorHandlerContext";
import Drawer from "components/Drawer";
import FormLabel from "components/FormLabel";
import { VehicleColor, vehicleColors } from "utils/VehicleConsts";
import { VehiclesMake } from "api/MonitoredVehiclesDashboardAPI";
import { LocationFilter } from "api/LocationAPI";

const ContentContainer = styled(Box)(() => ({
  height: "calc(100vh - 200px)",
  overflow: "auto"
}));

const Content = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(0)} ${theme.spacing(4)} ${theme.spacing(
    4
  )} ${theme.spacing(4)}`,
  maxHeight: "100%"
}));

const Footer = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
  display: "flex",
  justifyContent: "flex-end",
  borderTop: `1px solid ${theme.palette.grey["200"]}`,
  "& button": {
    marginLeft: theme.spacing(1)
  }
}));

export type FormFilterMonitoring = {
  id: string;
  startDate: string | Date;
  endDate: string | Date;
  startTime: string | Date;
  endTime: string | Date;
  plate: string;
  description: string;
  equipment: string[];
  make?: string;
  model?: string;
  color?: string;
};

const defaultDate = new Date();

const defaultValues: FormFilterMonitoring = {
  id: "",
  startDate: startOfDay(new Date()),
  endDate: new Date(),
  startTime: startOfDay(defaultDate),
  endTime: endOfDay(defaultDate),
  plate: "",
  description: "",
  equipment: [],
  make: undefined,
  model: undefined,
  color: undefined
};

type Props = {
  open: boolean;
  setOpen: (isOpen: boolean) => void;
  onFilter: (data: FormFilterMonitoring) => void;
  makes: VehiclesMake[];
};

const MonitoringReportFilter: FC<Props> = ({
  open,
  onFilter,
  setOpen,
  makes
}) => {
  const { t } = useTranslation();
  const [equipmentSelected, setEquipmentSelected] = useState<string[]>([
    t("form.all")
  ]);
  const [makeSelected, setMakeSelected] = useState<VehiclesMake>({
    name: "",
    isDivider: false
  });
  const [colorSelected, setColorSelected] = useState<VehicleColor>({
    color: ""
  });
  const [specificModels, setSpecificModels] = useState<string[]>([]);
  const [genericModels, setGenericModels] = useState<string[]>([]);
  const [specificModelSelected, setSpecificModelSelected] =
    useState<string>("");
  const [genericModelSelected, setGenericModelSelected] = useState<string>("");
  const [isSpecificModelLoading, setSpecificModelLoading] =
    useState<boolean>(false);
  const [isGenericModelLoading, setGenericModelLoading] =
    useState<boolean>(false);
  const [isRequestingEquipments, setRequestingEquipments] =
    useState<boolean>(false);
  const [, setSearchLocation] = useState("");
  const [locationsName, setlocationsName] = useState<string[]>([]);
  const [locationsComments, setLocationsComments] = useState<LocationFilter[]>(
    []
  );
  const { sessionUser } = useAuth();
  const ToolsAPI = useToolsAPI();
  const LocationAPI = useLocationAPI();
  const { errorHandler } = useErrorHandler();

  const { control, handleSubmit, reset, setValue } =
    useForm<FormFilterMonitoring>({
      defaultValues
    });

  const clearForm = () => {
    setEquipmentSelected([t("form.all")]);
    reset();
    const d = new Date();
    setValue("startDate", startOfDay(d));
    setValue("endDate", d);
    setValue("startTime", startOfDay(d));
    setValue("endTime", endOfDay(d));
    setMakeSelected({ name: "", isDivider: false });
    setGenericModelSelected("");
    setSpecificModelSelected("");
    setColorSelected({ color: "" });
  };

  const setSpecificModelOptions = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setSpecificModelLoading(true);
    try {
      const response = await ToolsAPI.getSpecificModels(makeSelected.name);
      setSpecificModels(response.model.map(item => item.name) || []);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setSpecificModelLoading(false);
    }
  };

  const setGenericModelOptions = async () => {
    if (!sessionUser?.["customer_id"]) return;
    setGenericModelLoading(true);
    try {
      const response = await ToolsAPI.getGenericModels(makeSelected.name);
      setGenericModels(response.model.map(item => item.name) || []);
    } catch (error: unknown) {
      const err = error as AxiosError;
      if (err.response?.data.error.code === 4054) {
        setGenericModels([]);
      } else {
        errorHandler({ error });
      }
    } finally {
      setGenericModelLoading(false);
    }
  };

  useEffect(() => {
    if (makeSelected.name === "") {
      setSpecificModelSelected("");
      setGenericModelSelected("");
    } else {
      setSpecificModelSelected("");
      setSpecificModelOptions();
      setGenericModelSelected("");
      setGenericModelOptions();
    }
  }, [makeSelected]);

  const onSubmit = async (data: FormFilterMonitoring) => {
    const newData = { ...data };

    if (equipmentSelected.includes(t("form.all"))) {
      newData.equipment = [];
    } else {
      newData.equipment = equipmentSelected;
    }

    newData.make = makeSelected.name;

    if (specificModelSelected === "" && genericModelSelected !== "") {
      newData.model = genericModelSelected;
    } else if (genericModelSelected === "" && specificModelSelected !== "") {
      newData.model = specificModelSelected;
    } else {
      newData.model = undefined;
    }

    newData.color = colorSelected.color;

    onFilter({
      ...data,
      equipment: newData.equipment,
      make: newData.make,
      model: newData.model,
      color: newData.color
    });
    setOpen(false);
  };

  const requestEquipments = useCallback(async () => {
    if (!sessionUser?.["customer_id"]) return;
    setRequestingEquipments(true);
    try {
      const locationResponse = await LocationAPI.listAll(
        sessionUser["customer_id"]
      );
      setlocationsName([
        t("form.all"),
        ...locationResponse.data.map(equip => equip["location_name"])
      ]);
      setLocationsComments([
        t("form.all"),
        ...locationResponse.data.map(location => ({
          ["location_name"]: location["location_name"],
          comment: location["location_data"].comment
        }))
      ]);
    } catch (error) {
      errorHandler({ error });
    } finally {
      setRequestingEquipments(false);
    }
  }, [sessionUser]);

  useEffect(() => {
    requestEquipments();
  }, [requestEquipments]);

  return (
    <Drawer open={open} setOpen={setOpen} title={t("CaptureReportPage.filter")}>
      <Divider sx={{ mb: 2 }} />
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <ContentContainer>
          <Content>
            <Grid container rowSpacing={3} columnSpacing={2}>
              <Grid item xs={8}>
                <Controller
                  name="startDate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <DatePickerField
                      label={t("MonitoringReportPage.startDateTime")}
                      field={{ ...field }}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="startTime"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TimePickerField
                      field={{ ...field }}
                      fieldState={fieldState}
                      sx={{ mt: 3 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8}>
                <Controller
                  name="endDate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <DatePickerField
                      label={t("MonitoringReportPage.endDateTime")}
                      field={{ ...field }}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={4}>
                <Controller
                  name="endTime"
                  control={control}
                  render={({ field, fieldState }) => (
                    <TimePickerField
                      field={{ ...field }}
                      fieldState={fieldState}
                      sx={{ mt: 3 }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("MonitoringReportPage.equipment")}</FormLabel>
                <Autocomplete
                  multiple
                  disablePortal
                  size="small"
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  noOptionsText={t("form.noOptions")}
                  options={locationsName}
                  getOptionLabel={option => option}
                  value={equipmentSelected}
                  loading={isRequestingEquipments}
                  disabled={isRequestingEquipments}
                  onChange={(event, values) => {
                    event.preventDefault();
                    const noIsAll = !values.includes(t("form.all"));
                    if (
                      equipmentSelected.includes(t("form.all")) &&
                      equipmentSelected.length === 1
                    ) {
                      const idx = values.indexOf(t("form.all"));
                      values.splice(idx, 1);
                      setEquipmentSelected(values);
                    } else if (noIsAll) {
                      setEquipmentSelected(values);
                    } else {
                      setEquipmentSelected([t("form.all")]);
                    }
                  }}
                  renderOption={(props, values) => {
                    if (locationsComments.length > 0) {
                      const tooltipComment = values.includes(t("form.all"))
                        ? ""
                        : locationsComments[
                            locationsComments.findIndex(
                              x => x.location_name === values
                            )
                          ].comment;
                      return (
                        <Tooltip
                          title={`${tooltipComment}`}
                          leaveDelay={0}
                          disableInteractive
                        >
                          <li {...props}>{values}</li>
                        </Tooltip>
                      );
                    }
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        onChange={event => {
                          setSearchLocation(event.target.value);
                        }}
                        placeholder={t(
                          isRequestingEquipments
                            ? "waitState.loading"
                            : "form.startTyping"
                        )}
                      />
                      {isRequestingEquipments && (
                        <CircularProgress
                          color="secondary"
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "42px"
                          }}
                        />
                      )}
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider>
                  <Typography variant="h5" color="primary.light">
                    {t("DefaultPageLayout.plateSearch")}
                  </Typography>
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="plate"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label={t("MonitoringReportPage.plate")}
                      uppercase
                      gutter={false}
                      field={{ ...field }}
                      fieldState={fieldState}
                      customProps={{
                        inputProps: {
                          maxLength: 7
                        }
                      }}
                    />
                  )}
                />
                <Typography
                  variant="caption"
                  align="left"
                  color="primary.light"
                >
                  {t("DefaultPageLayout.tooltipPlate")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider>
                  <Typography variant="h5" color="primary.light">
                    {t("DefaultPageLayout.characteristicsSearch")}
                  </Typography>
                </Divider>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("MonitoringReportPage.make")}</FormLabel>
                <Autocomplete
                  disablePortal
                  size="small"
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  options={makes}
                  noOptionsText={t("form.noOptions")}
                  getOptionLabel={option => option.name}
                  value={makeSelected}
                  onChange={(event, value) => {
                    event.preventDefault();
                    setMakeSelected({
                      name: value?.name || "",
                      isDivider: false
                    });
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        placeholder={t("form.startTyping")}
                      />
                    </div>
                  )}
                  renderOption={(props, value) => (
                    <>
                      <li {...props}>{value.name}</li>
                      {value.isDivider === true ? <Divider /> : null}
                    </>
                  )}
                />
                <Typography
                  align="left"
                  variant="caption"
                  color="primary.light"
                >
                  {t("DefaultPageLayout.tooltipModel")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("MonitoringReportPage.genericModel")}</FormLabel>
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={
                    makeSelected.name === "" ||
                    isGenericModelLoading ||
                    specificModelSelected !== ""
                  }
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  options={genericModels}
                  noOptionsText={t("form.noOptions")}
                  getOptionLabel={option => option}
                  value={genericModelSelected}
                  onChange={(event, value) => {
                    event.preventDefault();
                    setGenericModelSelected(value || "");
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        placeholder={t("form.startTyping")}
                      />
                      {isGenericModelLoading && (
                        <CircularProgress
                          color="secondary"
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "42px"
                          }}
                        />
                      )}
                    </div>
                  )}
                />
                <Divider>
                  <Typography variant="subtitle1" color="primary.light">
                    {t("DefaultPageLayout.or")}
                  </Typography>
                </Divider>
                <FormLabel>{t("MonitoringReportPage.specificModel")}</FormLabel>
                <Autocomplete
                  disablePortal
                  size="small"
                  disabled={
                    makeSelected.name === "" ||
                    isSpecificModelLoading ||
                    genericModelSelected !== ""
                  }
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  options={specificModels}
                  noOptionsText={t("form.noOptions")}
                  getOptionLabel={option => option}
                  value={specificModelSelected}
                  onChange={(event, value) => {
                    event.preventDefault();
                    setSpecificModelSelected(value || "");
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        placeholder={t("form.startTyping")}
                      />
                      {isSpecificModelLoading && (
                        <CircularProgress
                          color="secondary"
                          size={24}
                          sx={{
                            position: "absolute",
                            top: "10px",
                            right: "42px"
                          }}
                        />
                      )}
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <FormLabel>{t("MonitoringReportPage.color")}</FormLabel>
                <Autocomplete
                  disablePortal
                  size="small"
                  openText={t("action.open")}
                  closeText={t("action.close")}
                  clearText={t("action.clear")}
                  options={vehicleColors}
                  noOptionsText={t("form.noOptions")}
                  getOptionLabel={option => option.color}
                  value={colorSelected}
                  onChange={(event, value) => {
                    event.preventDefault();
                    setColorSelected({ color: value?.color || "" });
                  }}
                  renderInput={params => (
                    <div style={{ position: "relative" }}>
                      <TextField
                        {...params}
                        placeholder={t("form.startTyping")}
                      />
                    </div>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="description"
                  control={control}
                  render={({ field, fieldState }) => (
                    <InputField
                      label={t("MonitoringReportPage.description")}
                      gutter={false}
                      field={{ ...field }}
                      fieldState={fieldState}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Content>
        </ContentContainer>
        <Footer>
          <Button
            customProps={{
              color: "primary",
              variant: "outlined",
              onClick: clearForm,
              disabled: isRequestingEquipments
            }}
          >
            {t("action.clear")}
          </Button>
          <Button
            customProps={{
              type: "submit",
              disabled: isRequestingEquipments
            }}
          >
            {t("action.filter")}
          </Button>
        </Footer>
      </form>
    </Drawer>
  );
};

export default MonitoringReportFilter;
